<template>
  <div class="SupplierColorAutoComplete">
    <input
      ref="input"
      v-model="value"
      type="text"
      :placeholder="placeholder"
      @blur="onBlur"
      @input="onInput"
      @keydown="onKeydown"
      @focus="onFocus"
    >
    <Spinner v-if="lookup.isLoading" />
    <div
      class="label"
    >
      <span
        v-if="label && showLabel"
        :title="label"
      >
        {{ label }}
      </span>
    </div>

    <portal
      v-if="showResults"
      to="auto-complete"
    >
      <div
        ref="resultList"
        class="resultList"
        :class="`${type}`"
        :style="getResultListStyle()"
      >
        <div
          v-for="(result, index) in lookup.results"
          :key="result[valueKey]"
          ref="items"
          class="resultItem"
          :class="{ selected: isResultSelected(index) }"
          @mousedown="onResultMouseDown(result, index)"
        >
          {{ getItemLabel(result) }}
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import debounce from 'debounce-promise'
import Spinner from './Spinner.vue'

export default {
  components: {
    Spinner
  },
  inject: [ 'store' ],
  props: {
    focus: {
      type: Boolean,
      default: false
    },
    labeler: {
      type: [ Function, String ],
      required: false,
      default: undefined
    },
    perPage: {
      type: Number,
      default: 25
    },
    maxPageHeight: {
      type: Number,
      default: 10
    },
    maxPageWidth: {
      type: Number,
      default: 500
    },
    model: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    seasonId: {
      type: String,
      default: undefined
    },
    selectOnFocus: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    allowViewall: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    valueKey: {
      type: String,
      required: true
    },
    materialCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lookup: this.store.lookups.register(this._uid)
    }
  },
  computed: {
    label () {
      return this.getLabel(this.resultByValue)
    },
    resultByValue () {
      return this.lookup.results.find((result) => {
        return result[this.valueKey] === this.value
      })
    },
    showResults () {
      return this.lookup.showResults && this.lookup.results.length > 0
    },
    value: {
      get () {
        return this.model[this.name]
      },
      set (value) {
        this.model[this.name] = value
      }
    }
  },
  watch: {
    label (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.emitChangeEvent()
      }
    },
    'lookup.selectedIndex' (newValue, oldValue) {
      if (this.showResults) {
        if (newValue !== oldValue) {
          if (newValue >= 0 && newValue < this.$refs.items.length) {
            const el = this.$refs.items[newValue]
            if (!this.isScrolledIntoView(el)) {
              el.scrollIntoView({ block: 'nearest' })
            }
          }
        }
      }
    },
    value (newValue, oldValue) {
      if (
        newValue !== oldValue && oldValue &&
        newValue && this.materialCode
      ) {
        this.fetch(newValue)
      } else if (!newValue) {
        this.lookup.hide()
        this.lookup.reset()
      }
      if (newValue !== oldValue) {
        this.emitChangeEvent()
      }
    }
  },
  created () {
    // This gives us a debounced instance of this function per
    // component instance.
    this.fetchDebounced = debounce(this.lookup.fetchSupplierColor, 250)
  },
  mounted () {
    if (this.focus) {
      this.$nextTick(() => {
        if (this.$refs.input) {
          this.$refs.input.focus()
        }
      })
    }
  },
  methods: {
    blur () {
      this.$refs.input.blur()
    },
    emitChangeEvent () {
      this.$emit('change', {
        value: this.value,
        label: this.label
      })
    },
    emitResultSelected () {
      this.$emit('resultSelected')
    },
    fetch (term) {
      this.fetchDebounced({
        id: this._uid,
        type: this.type,
        term,
        seasonId: this.seasonId,
        perPage: this.perPage,
        allowViewAll: this.allowViewall
      })
    },
    setFocus () {
      this.$refs.input.focus()
    },
    selectText () {
      this.$refs.input.select()
    },
    getItemLabel (result) {
      const label = this.getLabel(result)
      if (label.length === 0) {
        // If the labeler can't find anything, show the value
        return result[this.valueKey]
      }
      return label
    },
    getLabel (result) {
      if (result) {
        if (typeof this.labeler === 'function') {
          return this.labeler(result)
        }
        if (typeof this.labeler === 'string') {
          return result[this.labeler]
        }
      }
      return ''
    },
    getResultListStyle () {
      const rect = this.$refs.input.getBoundingClientRect()
      const itemHeight = 24
      const itemPadding = 3
      const maxHeight = itemHeight * this.maxPageHeight
      const maxPageWidth = `${this.maxPageWidth}px` || 'auto'
      return {
        '--left': `${rect.left}px`,
        '--top': `${rect.bottom + 8}px`,
        '--width': `${rect.width}px`,
        '--maxHeight': `${maxHeight}px`,
        '--itemHeight': `${itemHeight}px`,
        '--itemPadding': `${itemPadding}px`,
        '--maxPageWidth': maxPageWidth
      }
    },
    isScrolledIntoView (el) {
      const child = el.getBoundingClientRect()
      const parent = el.closest('.resultList').getBoundingClientRect()
      const topDiff = child.top - parent.top
      const bottomDiff = child.bottom - parent.bottom
      if (topDiff >= 0 && bottomDiff <= 0) {
        return true
      }
      return false
    },
    isResultSelected (index) {
      return index === this.lookup.selectedIndex
    },
    onBlur () {
      this.lookup.hide()
      this.$emit('blur')
    },
    onFocus () {
      if (this.selectOnFocus) {
        this.selectAll()
        this.fetchValuesOnFocus()
      }
    },
    onInput (event) {
      this.model[this.name] = event.target.value
    },
    onKeydown (event) {
      if (event.key === 'Enter' && this.showResults) {
        event.preventDefault()
        event.stopPropagation()
        if (this.lookup.selectedResult) {
          this.value = this.lookup.selectedResult[this.valueKey]
          this.$nextTick(this.lookup.hide)
        } else {
          this.lookup.hide()
        }
      } else if (event.key === 'Escape' & this.showResults) {
        event.stopPropagation()
        this.lookup.hide()
      } else if (event.key === 'ArrowDown') {
        event.preventDefault()
        if (this.showResults) {
          this.lookup.selectNext()
        } else {
          this.lookup.show()
          this.fetch(event.target.value)
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault()
        if (this.showResults) {
          this.lookup.selectPrevious()
        }
      } else if (event.key === 'PageUp') {
        event.preventDefault()
        this.lookup.pageUp()
      } else if (event.key === 'PageDown') {
        event.preventDefault()
        this.lookup.pageDown()
      }
    },
    onResultMouseDown (result, index) {
      this.lookup.selectedIndex = index
      this.value = result[this.valueKey]
      this.emitResultSelected()
    },
    selectAll () {
      const { input } = this.$refs
      input.setSelectionRange(0, input.value.length, 'forward')
    },
    fetchValuesOnFocus () {
      if (this.materialCode) {
        this.fetch('')
      }
    }
  }
}
</script>

<style lang="scss">
.SupplierColorAutoComplete {
  position: relative;

  input {
    width: 100%;
  }

  .Spinner {
    position: absolute;
    right: 8px;
    top: 8px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .label {
    height: 14px;
    margin: 1rem 1rem 0 1rem;
    font-size: 13px;
    color: $gray-inactive;
    width: calc(100% - 2rem);
    overflow: hidden;

    span {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.gridMode {
    display: flex;
    height: 100%;

    input {
      height: 100%;
      padding: 0 4px;
      border: none;
      box-shadow: none;
      border-radius: 0;
      font-size: 14px;
    }

    .Spinner {
      right: 4px;
      top: 4px;

      svg {
        height: 14px;
        width: 14px;
      }
    }

    .label {
      display: none;
    }
  }
}

.auto-complete-portal-target {
  .resultList {
    position: fixed;
    background-color: $white;
    border: 1px solid $gray-lines;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    left: var(--left);
    top: var(--top);
    min-width: var(--width);
    max-height: var(--maxHeight);
    overflow-y: auto;
    border-radius: 4px;

    .resultItem {
      height: var(--itemHeight);
      padding: var(--itemPadding) 16px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover,
      &.selected {
        background-color: $blue;
        color: white;
      }
    }

    &.component {
      .resultItem {
        text-transform: uppercase;
      }
    }

    &.componentLocation {
      .resultItem {
        text-transform: capitalize;
      }
    }
  }
}
</style>
